<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            />
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header v-show="categoryMode" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                <p v-if="!categoryMode" class="title">Расшифровка. {{ currCategory.recipient }}</p>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex" >
                    <template v-if="categoryMode">
                        <c-budget-forms-copy-data
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="budgetForm"
                            :header="header"
                            :isLoad="isLoad"
                            @keyPress="keyPress"
                        />
                        <b-button variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> Добавить</b-button>
                    </template>
                    <template v-else>
                        <b-button variant="light" @click="resetModal">
                           <i class="icon icon-keyboard icon-rotate-180"></i> Назад к списку
                        </b-button>
                        <b-button v-if="variantAttribute" variant="primary" @click="$refs.formTemplate.addItem()">
                            <i class="icon icon-plus-circle"></i>Добавить
                        </b-button>
                        <b-button
                            :disabled="!dataChanged || goodsAreSaving"
                            variant="success"
                            @click="prepareForSave">
                            Сохранить
                        </b-button>
                    </template>
                </div>
            </div>
        </div>
        <breadcrumbs-filter
            v-show="categoryMode"
            :header="{...header, formName: this.formName, spfName: this.spfName}"
            :data-type-filter="dataTypeFilter"
            :variant-name="variantName"
            @openFilterByRef="openFilterByRef"
        />
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        />
        <div v-show="categoryMode" class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
            >
                <template #top-row="data">
                    <td></td>
                    <td class="td-numbering"></td>
                    <td class="td-numbering text-center table-success">1</td>
                    <td class="td-numbering text-center table-danger">2</td>
                    <td class="td-numbering text-center table-info">3</td>
                    <td class="td-numbering text-center table-primary">4</td>
                    <td class="td-numbering text-center table-warning">5</td>
                    <td class="td-numbering text-center table-success">6</td>
                    <td class="td-numbering text-center table-danger">7</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(selection)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            title="Удалить выбранные записи"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? 'всех' : 'выбранных'} категорий`)"/>
                    </div>
                </template>
                <template #cell(selection)="data">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(action)="data">
                    <button
                        class="table-plus"
                        v-b-modal.goods-modal @click="setCurrCatName({...data.item, code: data.item.category_id})" :disabled="data.item.checkForDuplicates || !data.item.recipient || data.item.nameUpdating">
                        <i class="icon icon-plus-circle"/>
                    </button>
                </template>
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <template #cell(recipient)="data">
                    <div
                        class="column-width-100"
                        :class="{ 'error': data.item.checkForDuplicates }">
                        <b-form-input v-if="variantAttribute"
                                      style="min-width: 90%"
                                      @update="data.item.rowToSave = true"
                                      v-model.trim="data.item.recipient"
                                      @blur="() => { if (data.item.recipient) prepareForSaveCats(budgetForm); }"
                                      @change="onChangeName($event, data.item, 'recipient')"
                                      @keyup.enter.exact="keyup13">
                        </b-form-input>
                        <div v-else>{{ data.value }}</div>
                        <template v-if="data.item.checkForDuplicates">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                <p>Такая запись уже в базе существует</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(average_meals)="data">
                    <div class="text-right">{{ $n(parseInt(data.value)) }}</div>
                </template>
                <template #cell(func_day)="data">
                    <div class="text-right">{{ $n(parseInt(data.value)) }}</div>
                </template>
                <template #cell(cost_meals)="data">
                    <div class="text-right">{{ data.value | pointToComma }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onAllFilesClick({ ...data.item, name_ru: data.item.recipient})"
                    >({{data.item.files ? data.item.files.length : 0}})</span>
                </template>
                <template #cell(more)="data">
                    <i class="icon icon-clear table-remove"  v-if="variantAttribute" :disabled="deletingCat" @click="deleteItem('данной категории', {...data.item, good_type: data.item}, data.index, true)"></i>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="4">ИТОГО</td>
                    <td class="text-right">{{ isNaN(averageMeals) ? 0 : $n(averageMeals) }}</td>
                    <td colspan="2"></td>
                    <td class="text-right">{{ isNaN(total) ? 0 : $n(total) }}</td>
                    <td colspan="3"></td>
                </template>
            </b-table>
            <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
                <span @click="addItem"><i class="icon icon-plus-circle"></i> Добавить</span>
            </div>
        </div>
        <template v-if="!categoryMode">
            <b-overlay :show="templateTableOverlay" rounded="sm">
                <form-template4
                    ref="formTemplate"
                    :formCode="form.code"
                    :goodsData="goodsData"
                    :initialGoodsData="initialGoodsData"
                    :ensTruDict="ensTruDict"
                    :variantAttribute="variantAttribute"
                    :currCategory="currCategory"
                    :lang="lang"
                    :ifAllFieldsFilled="ifAllFieldsFilled"
                    :deleteGood="deleteGood"
                    :getUnitName="getUnitName"
                    :ensTruNamePrt="ensTruNamePrt"
                    :ensTruName="ensTruName"
                    :currUnits="currUnits"
                    :descIsLoading="descIsLoading"
                    :parNameLoading="parNameLoading"
                    :allFiles="allFiles"
                    :idxOfInfl="idxOfInfl"
                    @setTotals="setTotals"
                    @updateDatas="updateDatas"
                    @save="save"
                    @onDataChanged="onDataChanged"
                    @searchName="searchName"
                    @checkNumber="checkNumber"
                    @setIsAllDelete="setIsAllDelete"
                    @deleteSeveralGoods="deleteSeveralGoods"
                    :triggDownloadFile="triggDownloadFile"
                    :showFileUploadModal="showFileUploadModal"
                    :getEnstruByName="getEnstruByName"
                    :loadCurUnits="loadCurUnits"
                    :goodsAreSaving="goodsAreSaving"
                ></form-template4>
                <div class="table-add" v-if="variantAttribute" >
                    <span @click="$refs.formTemplate.addItem()"><i class="icon icon-plus-circle"></i> Добавить</span>
                </div>
            </b-overlay>
        </template>
        <files-updown ref="filesUpdown"
            v-show="categoryMode"
            :header="header"
            :variant-attribute="variantAttribute"
            :load="load"
            :showDeleteLinks="variantAttribute"
            :showDeleteAll="true"
            @getFiles="getFiles"
            @fillFilesList="fillFilesList"
            @restetFilesList="restetFilesList"
            @delelteAttachedFile="delelteAttachedFile"
        />
        <modal-all-files-management
            ref="modalAllFilesManagement"
            :allFiles="allFiles"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"

        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import FormTemplate4 from "./components/budget-forms-template4.vue";
import FormsHandlerMixin from "./mixins/forms-handler-mixin";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';

export default {
    name: 'Form01_141',
    components: {
        BudgetHeader,
        FilesUpdown,
        BudgetFormsList,
        BreadcrumbsFilter,
        FormsDownloadReprt,
        ModalAllFilesManagement,
        FormTemplate4,
        CBudgetFormsCopyData
    },
    mixins: [FormsHandlerMixin],
    data() {
        return {
            form: {
                code: '01-141',
                name_kk: 'Бiлiм беру және әлеуметтiк қорғау мекемелерiнде тамақтануға арналған шығыстарды есептеу',
                name_ru: 'Расчет расходов на питание в учреждениях образования и социальной защиты'
            },
            tableFields: [
                {
                    key: 'selection',
                    label: ' '
                },
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'index',
                    label: ' № '
                },
                {
                    key: 'recipient',
                    label: 'Наименование получателей'
                },
                {
                    key: 'average_meals',
                    label: 'Среднее кол-во питающихся в день (ед.)'
                },
                {
                    key: 'func_day',
                    label: 'Дни функционирования учреждения (дни)'
                },
                {
                    key: 'cost_meals',
                    label: 'Норма питания на 1 единицу в день (тенге)'
                },
                {
                    key: 'total',
                    label: 'Сумма затрат (тыс.тенге) (гр.3 х гр.4 х гр.5)/1000'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1
        };
    },
    mounted() {
        this.progress = 15;
    },
    methods: {
        openModalRowFilesByRowId(rowId, isAdd) {
            this.row_files = this.budgetForm.find(b => b.id === rowId)['row_files'];
            this.$refs.modalAttach.openModalRowAddFilesByRowId(rowId);
            this.rowId = rowId;
            this.isAdd = isAdd;
        },
        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },
        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                let num_attach_files = 0;
                item['row_files'] = [...item['row_files'], ...files];
                item['row_files'].forEach((file) => {
                    if (file.row_id === item['id']) {
                        num_attach_files += 1;
                    }
                });
                item['files'] = num_attach_files;
            });
            this.$refs.modalAttach.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
            this.$refs.modalAttach.delFile(fileId);
        },
        onDataChanged(item) {
            this.$set(item, "rowToSave", true)
            this.dataChanged = true;
            this.setPassVals(item);
        }, // переключения тригера при изменении данных товаров

        prepareData(item) {
            item.normMoneyNumDecimals = this.form.code === '01-141' ? 4 : 2;
            Object.defineProperty(item, 'norm_money', {
                get: function () {
                    return parseFloat((parseFloat(parseFloat(item.norm_per).toFixed(6)) * parseFloat(parseFloat(item.price_cur).toFixed(2))).toFixed(item.normMoneyNumDecimals));
                },
                set: function(value) {}
            })
            Object.defineProperty(item, 'year_value', {
                get: function () {
                    return parseFloat((parseInt(item.num_meals) * parseInt(item.num_day) * parseFloat(parseFloat(item.norm_per).toFixed(6))).toFixed(2));
                },
                set: function(value) {}
            })

            this.setPassVals(item);
        },

        setTotal(item) {
            this.$set(item, 'total', parseFloat(parseFloat(parseFloat(item.total_goods) + parseInt(item.average_meals)*parseFloat(item.tobacco)*parseFloat(item.months)/1000).toFixed(3)));
        },

        setTotals(item) {
            Object.defineProperty(item, 'total', {
                get: function () {
                    return parseFloat((item.price_cur * item.year_value / 1000).toFixed(3));
                },
                set: function(value) {}
            });
        }, // расчет для расшифровок

        async delete(item, showMsg = true) {
            this.$set(item, 'form', this.form.code);
            try {
                const response = await fetch('/api-py/delete-budget-request-form/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (showMsg) {
                        await this.updateDatas(true);
                        this.makeToast('success', 'Сообщение', 'Запись удалена');
                    }
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления категории');
            }
        }, // удаление категорий

        itemUpdate(item) {
            this.$set(item, 'recipient', '');
            this.$set(item, 'average_meals', 0);
            this.$set(item, 'func_day', 0);
            this.$set(item, 'cost_meals', 0);
            this.$set(item, 'total', 0);
            this.$set(item, 'total_goods', 0);
            this.$set(item, 'rowToSave', true);
            this.$set(item, 'newCat', true);
            this.$set(item, 'oldName', '');
            this.$set(item, 'nameUpdating', false);
            this.$set(item, 'checkForDuplicates', false);
            this.$set(item, 'category_id', this.uuidv4());
            this.$set(item, 'code', item.category_id);
            this.$set(item, 'itemToDelete', false);
        },
        getFiles(data) {
            this.files = data;
        },

        async loadCategoryData() {
            await this.loadDatas();
            if (!this.budgetForm.length) return;
            const catIdList = [];
            this.budgetForm.forEach(item => catIdList.push(item.category_id))
            this.$set(this.header, 'cat_id_list', catIdList)
            await this.loadCatData();
            if (this.variantAttribute) this.saveTotal()
            this.load = false;
        },

        async loadDatas() {
            const that = this;

            this.load = true;
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form/' + JSON.stringify(this.header));
                if (Math.floor(response.status) / 100 === 2) {
                    values = await response.json();
                } else {
                    this.makeToast('danger', 'Ошибка запроса loadDatas()');
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDatas()', error.toString());
            }

            // в этот массив записывается все наименование записей, потом по этому массиву идет проверка
            // если в него есть массив то атрибут checkForDuplicates равен true            const newBudgetForm = [];
            const newBudgetForm = [];
            values.forEach(val => {
                const item = {
                    id: val.id,
                    recipient: val.recipient,
                    category_id: val.category_id,
                    code: val.category_id,
                    average_meals: val.average_meals ? val.average_meals : 0,
                    func_day: val.func_day ? val.func_day : 0,
                    cost_meals: val.cost_meals ? val.cost_meals : 0,
                    total: 0,
                    total_goods: 0,
                    rowToSave: false,
                    newCat: false,
                    oldName: val.recipient,
                    nameUpdating: false,
                    itemToDelete: false,
                    checkForDuplicates: false
                };

                Object.defineProperty(item, 'total', {
                    get: function () {
                        const total = (that.number(item.average_meals) * that.number(item.func_day) * that.number(item.cost_meals) / 1000);
                        return parseFloat((Math.round(total * 1000) / 1000).toFixed(3));
                    }
                });
                newBudgetForm.push(item);
            });
            this.budgetForm = newBudgetForm.sort((a, b) => a.id - b.id);
            this.load = false;
        },

        async loadCatData() {
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat/' + JSON.stringify(this.header));
                if (response.status === 200) {
                    const values = await response.json();
                    if (values) {
                        this.budgetForm.forEach(item => {
                            const currSums = values.find(val => val.category_id === item.category_id);
                            if (currSums) {
                                this.$set(item, 'total_goods', currSums.sum_total_spends)
                                this.$set(item, 'total', parseFloat(parseFloat(currSums.max_func_days * currSums.avg_num_meals * currSums.norm_in_money / 1000).toFixed(3)))
                                this.$set(item, 'files', this.getUnicFilesArr(currSums));
                                this.$set(item, 'average_meals', Math.round(currSums.avg_num_meals));
                                this.$set(item, 'func_day', Math.round(currSums.max_func_days));
                                this.$set(item, 'cost_meals', parseFloat(parseFloat(currSums.norm_in_money).toFixed(4)));
                            } else {
                                this.$set(item, 'total', item.total === null ? 0 : item.total)
                                this.$set(item, 'files', [])
                                this.$set(item, 'average_meals',  item.average_meals === null ? 0 : item.average_meals);
                                this.$set(item, 'func_day', item.func_day === null ? 0 : item.func_day);
                                this.$set(item, 'cost_meals',  item.cost_meals === null ? 0 : item.cost_meals);
                            };

                        });
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadCategoryData()', error.toString());
            }
        },

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        ifAllFieldsFilled() {
            const prevNewItemIdx = this.goodsData.findIndex(item => item.isNew === true);
            if (prevNewItemIdx !== -1) {
                const prevNewItem = this.goodsData[prevNewItemIdx];
                if (!prevNewItem.enstru_code) {
                    this.makeToast('warning', 'Внимание', `Наименование не выбрано`);
                    return false;
                };
            }
            if (this.goodsData.findIndex(item => item.nummealsPassVal === false) !== -1) {
                this.makeToast('warning', 'Внимание', `Количество питающихся должно быть больше нуля`);
                return false;
            }
            if (this.goodsData.findIndex(item => item.numdayPassVal === false) !== -1) {
                this.makeToast('warning', 'Внимание', `Количество дней должно быть больше нуля`);
                return false;
            }
            if (this.goodsData.findIndex(item => item.normperPassVal === false) !== -1) {
                this.makeToast('warning', 'Внимание', `Норма на человека должна быть больше нуля`);
                return false;
            }
            if (this.goodsData.findIndex(item => item.pricecurPassVal === false) !== -1) {
                this.makeToast('warning', 'Внимание', `Цена текущего года должна быть больше нуля`);
                return false;
            }

            if (prevNewItemIdx !== -1) this.$set(this.goodsData[prevNewItemIdx], 'isNew', false);
            return true
        },

        updateTotalBeforeSave(isCatDeleting = false) {
            const currTotal = isCatDeleting ? {totalAll: {
                        totalNumMeals: 0,
                        totalAvgDays: 0,
                        totalNormMoney: 0,
                        totalSpend: 0
                    }} : this.$refs.formTemplate.getTotal();
            const totalsAll = currTotal.totalAll;
            if (totalsAll.totalNumMeals >= 0 && totalsAll.totalAvgDays >= 0 && totalsAll.totalNormMoney >= 0) {
                for (const cat of this.budgetForm) {
                    if (cat.code === this.currCategory.code) {
                        this.$set(cat, 'average_meals', totalsAll.totalNumMeals);
                        this.$set(cat, 'func_day', totalsAll.totalAvgDays);
                        this.$set(cat, 'cost_meals', totalsAll.totalNormMoney);
                        this.$set(cat, 'total_goods', totalsAll.totalSpend);
                        this.setTotal(cat);
                    }
                }
            }
            this.updtHeader();
        },

         getFormCode() {
            // при необходимости заменить в родительском компоненте. Напр. Form01_142.vue
            return `${this.form.code}-decode`;
        },

        fillDataForSave(item, row) {
            this.$set(item, 'category_id', this.currCategory.category_id);
            this.$set(item, 'num_meals', parseInt(row.num_meals));
            this.$set(item, 'num_day', parseInt(row.num_day));
            this.$set(item, 'norm_per', parseFloat(row.norm_per));
            this.$set(item, 'price_cur', parseFloat(row.price_cur));
        }, // заполнение полей при подготовке к сохранению товаров

        getCodeFieldName() {
            return 'category_id';
        }, // при необходимости заменить в родителе
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        },
        averageMeals() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.average_meals);
            }
            return sum;
        }
    }
};
</script>

<style scoped>
.error .pop-up, .more-info .pop-up {
    opacity: 1;
    visibility:visible;
}
.error input {
    padding-left: 25px;
}
.filter-actions-flex {
    display: flex;
}
.table-plus {
    border: none;
}
</style>